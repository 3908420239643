<template>
  <div>
    <div class="bannerTeacher">
      <div class="teacherDetail">
        <el-image
          class="teacherImg"
          :src="teacher ? teacher.terImg : ''"
          fit="cover"
        />
        <el-image
          class="teacherBanner"
          :src="teacher ? teacher.terImg : ''"
          fit="cover"
        />
        <div class="teacherContent">
          <div class="teacherTitle">
            <span class="teacherName">{{ teacher.nickname }}</span>
            <div v-if="teacher && teacher.jobTitle" class="grade">
              {{ teacher.jobTitle }}
            </div>
            <div class="jobTitle" />
            <!-- <div class="grade">{{
                            teacher.roleId == 1 ? '全职讲师' : (teacher.roleId == 2 ? '兼职讲师' : '')
                        }}</div> -->
          </div>
          <div class="line" />
          <div class="mainContent">
            <div class="mainContentItem">
              <span class="title">辅导项目：</span>
              <span v-for="item in kindName" :key="item" class="text">{{
                item
              }}</span>
            </div>
            <div class="mainContentItem">
              <span class="title">授课课程：</span>
              <!-- <span class="text">{{ teacher.method }}</span> -->
              <div
                class="kc_list"
                @mousemove="mousemoves"
                @mouseleave="floatingWindowShows = false"
                @mouseenter="mouseenters"
              >
                <div
                  v-for="(item, index) in kcList"
                  :key="index"
                  class="kc_item"
                >
                  《{{ item }}》
                </div>
              </div>
              <div
                v-show="floatingWindowShows"
                class="floating_windows"
                v-html="kcStr"
              />
            </div>
            <div class="mainContentItem">
              <div class="title">讲师简介：</div>
              <div
                class="textBlock"
                @mousemove="mousemove"
                @mouseleave="mouseleave"
                @mouseenter="mouseenter"
                v-html="ImgSizeLimit(teacher.profile)"
              />
              <div
                v-show="floatingWindowShow"
                class="floating_window"
                v-html="ImgSizeLimit(teacher.profile)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contentWrap">
      <div class="tabsWrap">
        <!-- tabs标签 -->
        <div class="til">
          <div class="title">
            主讲课程
            <img src="@/assets/img/partner/corner.png" alt="">
          </div>
          <div @click="saveImg('/seventhPage/publicClass')">
            <!-- 查看更多 -->
            <!-- <i class="iconfont el-icon-arrow-right" style="font-size: 12px" /> -->
          </div>
        </div>
      </div>
      <div class="lectrue">
        <div class="lectrue_list">
          <div
            v-for="(item, index) in lectrueList"
            :key="index"
            class="lectrue_item"
          >
            <div class="imgBox">
              <img :src="item.img" alt="">
              <div class="tips">
                <!-- <div v-if="item.productLine == 2" class="tips-title">精品</div> -->
                <img v-if="item.productLine === '2'" src="@/assets/img/partner/jingpin.png" alt="">
                <div class="tips-type">{{ liveEnum[item.types] +' · '+ item.kindName }}</div>
              </div>
            </div>
            <div class="content">
              <div class="title twoEllipsis">{{ item.title }}</div>
              <div class="fire dis_flex_start">
                <img class="" src="@/assets/img/partner/fire.png" alt="">
                人气值{{ item.clicks }}
              </div>
              <div class="price">￥{{ item.price }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="questionsList.length == questionsPage.total" class="noMore">
        下面没有更多内容了哦~
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import answerView from '@/components/Know/course/answerView.vue'
import scoreEvaluation from '@/components/Know/course/scoreEvaluation.vue'
import { TeacherClient } from '@/api/teacherClient.js'
import Swipers from '../../components/swipers.vue'
import { liveEnum } from '@/api/emun'
const teacherClient = new TeacherClient()
export default {
  components: {
    answerView,
    scoreEvaluation
  },
  data() {
    return {
      kcStr: '',
      id: null,
      tabsList: [
        { label: '主讲课程', value: 2 },
        { label: '老师答疑', value: 0 }
        /* { label: '学员评价', value: 1 }, */
      ],
      info: 2,
      liveEnum: liveEnum,
      teacher: {}, // 老师详情
      kindName: [], // 辅导项目
      questionsPage: {
        pageNum: 1,
        pageSize: 5,
        total: 0
      },
      evaluatePage: {
        pageNum: 1,
        pageSize: 5,
        total: 0
      },
      questionsList: [], // 答疑列表
      lectrueList: [], // 主讲课程
      evaluateList: [], // 评价列表
      evaluationVal: '', // 评价内容
      evaluationNum: '', // 评分 星
      kcList: [],

      floatingWindowShow: false,
      floatingWindowShows: false,
      floatingWindow: null,

      courseNameImg: [
        {
          name: 'blue',
          img: require('@/assets/img/faculty/blue.png')
        },
        {
          name: 'orange',
          img: require('@/assets/img/faculty/orange.png')
        },
        {
          name: 'purple',
          img: require('@/assets/img/faculty/purple.png')
        },
        {
          name: 'yellow',
          img: require('@/assets/img/faculty/yellow.png')
        }
      ],

      courseTerList: []
    }
  },
  async created() {
    this.id = await this.$route.query.id
    this.getTeacher()
    this.getCourse()
  },
  mounted() {
    this.floatingWindow = document.querySelector('.floating_window')
    this.floatingWindows = document.querySelector('.floating_windows')
  },
  methods: {
    onTabs(index) {
      this.info = index
      /* this.page.pageNum = 1
                if (index == 0) {
                    this.questionsList.splice(1,this.questionsList.length - this.page.pageSize)
                } else {
                    this.evaluateList.splice(1,this.evaluateList.length - this.page.pageSize)
                } */
    },
    /* 点击加载更多 */
    getMore() {
      switch (this.info) {
        case 0:
          if (this.questionsList.length < this.questionsPage.total) {
            this.questionsPage.pageNum += 1
            this.selectStuQuestionsList()
          } else {
            this.$message('没有更多了')
          }

          break
        case 1:
          if (this.evaluateList.length < this.evaluatePage.total) {
            this.evaluatePage.pageNum += 1
            this.getTerPcEvaluateList()
          } else {
            this.$message('没有更多了')
          }
      }
    },
    /* 师资力量详情 */
    getTeacher() {
      const params = {
        lectureId: this.id
      }
      teacherClient.getPcById(params).then((res) => {
        this.kcList = res.data.kcList.map((item) => {
          return item.kcName
        })
        this.teacher = res.data.teacher
        this.$
        this.kindName = res.data.kindName
        this.selectStuQuestionsList()
        this.getTerPcEvaluateList()
      })
    },
    /* 答疑列表 */
    selectStuQuestionsList() {
      const data = {
        ...this.questionsPage,
        replyUserId: this.teacher.accountId
      }
      delete data.total
      teacherClient.selectStuQuestionsList(data).then((res) => {
        this.questionsList.push(...res.rows)
        this.questionsPage.total = res.total
        res.rows.forEach((item) => {
          if (item.img) {
            item.img = item.img.split(',')
          }
          item.replyList.forEach((i) => {
            if (i.replyImg) {
              i.replyImg = i.replyImg.split(',')
            }
          })
        })
      })
    },
    /* 评价列表 */
    getTerPcEvaluateList() {
      const data = {
        ...this.evaluatePage,
        teacherId: this.teacher.id
      }
      delete data.total
      teacherClient.getTerPcEvaluateList(data).then((res) => {
        this.evaluateList.push(...res.rows)
        this.evaluatePage.total = res.total
      })
    },
    // 主讲课程
    getCourse() {
      const data = {
        terId: this.id
      }
      teacherClient.getCourseTerList(data).then((res) => {
        console.log(res, '负责课程')
        res.data.forEach((item) => {
          this.lectrueList = [...this.lectrueList, ...item.array]
          // item.course = [];
          // let arr = [];
          // item.array.forEach((i, index) => {
          //   if ((index + 1) % 2 == 0) {
          //     arr.push(i);
          //     item.course.push(arr);
          //     arr = [];
          //   } else if (index + 1 < item.array.length) {
          //     arr.push(i);
          //   } else if (index + 1 == item.array.length) {
          //     arr.push(i);
          //     item.course.push(arr);
          //   }
          // });
        })
        console.log(res.data, 'dadadadadad')
        this.courseTerList = res.data
      })
    },
    toDetail(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: '/zhiboclassxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: '/videoxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: '/audioCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: '/imgCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: '/faceCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    mouseenter() {
      this.floatingWindowShow = true
    },
    mouseenters() {
      let str = ''
      console.log(this.kcList, '----------------12312')
      this.kcList.forEach((item, index) => {
        str += `《${item}》`
      })
      this.kcStr = str
      this.floatingWindowShows = true
    },
    mousemove(e) {
      console.log(e.screenX, 'X', e.screenY, 'Y')
      this.floatingWindow.style.top = `${e.screenY - 80}px`
      this.floatingWindow.style.left = `${e.screenX + 20}px`
    },
    mousemoves(e) {
      this.floatingWindows.style.top = `${e.screenY - 80}px`
      this.floatingWindows.style.left = `${e.screenX + 20}px`
    },
    mouseleave() {
      this.floatingWindowShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.bannerTeacher {
  width: 100%;
  height: 400px;
  background-image: url("~@/assets/img/Home/teacherDetail.png");
  .teacherDetail {
    width: 1220px;
    margin: auto;
    padding-top: 40px;
    position: relative;
    display: flex;
    .teacherImg {
      width: 240px;
      height: 360px;
      margin-left: 200px;
      margin-right: 20px;
    }
    .teacherBanner {
      width: 266px;
      height: 400px;
      position: absolute;
      top: 0;
      right: 50%;
      margin-right: -133px;
      opacity: 0.1;
    }
    .teacherContent {
      .teacherTitle {
        margin-top: 44px;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-end;
        .teacherName {
          font-size: 40px;
          color: #ffffff;
          font-weight: bold;
          margin-right: 16px;
        }
        .grade {
          font-size: 12px;
          border: 1px solid #ffffff;
          height: 28px;
          padding: 4px 8px;
          color: #ffffff;
          border-radius: 14px;
          margin-right: 8px;
        }
      }
      .line {
        width: 60px;
        height: 8px;
        background-color: #ffbe5e;
        margin-bottom: 24px;
      }
      .mainContent {
        .mainContentItem {
          display: flex;
          align-items: flex-start;
          margin-bottom: 12px;
          .title {
            width: 70px;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
          }
          .text {
            font-size: 14px;
            color: #ffffff;
            // line-height: 26px;
            margin-right: 5px;
            display: inline-block;
          }
          .textBlock {
            width: 520px;
            height: 96px;
            font-size: 14px;
            color: #ffffff;
            // line-height: 26px;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            position: relative;
            z-index: 1;
          }
          .kc_list {
            width: 520px;
            // height: 63px;
            overflow: hidden;
            // display: flex;
            // flex-wrap: wrap;
            color: #ffffff;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            z-index: 1;
            .kc_item {
              display: inline-block;
              margin-right: 1em;
              font-size: 14px;
            }
          }

          .floating_window {
            width: 550px;
            min-height: 100px;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            border-radius: 8px;
            color: #ffffff;
            padding: 10px 15px;
            cursor: pointer;
            z-index: 1;
          }
          .floating_windows {
            width: 550px;
            min-height: 100px;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            border-radius: 8px;
            color: #ffffff;
            padding: 10px 15px;
            cursor: pointer;
            z-index: 2;
          }
        }
      }
    }
  }
}
.contentWrap {
  width: 1200px;
  // background-color: #ffffff;
  margin: auto;
  padding-bottom: 24px;
  .more {
    color: #666666;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
  }
  .noMore {
    color: #666666;
    font-size: 14px;
    text-align: center;
  }
  .tabsWrap {
    height: 33px;
    display: flex;
    margin: 28px auto 15px;
    .til {
      width: 1200px;
      height: 33px;
      font-size: 24px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      display: flex;
      justify-content: space-between;
      .title {
        position: relative;
        img {
          width: 10px;
          position: absolute;
          right: -8px;
          bottom: 0;
        }
      }
      > div:nth-child(2) {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }
    }
    .tabsItem {
      width: 130px;
      height: 66px;
      text-align: center;
      line-height: 66px;
      color: #666666;
      font-size: 18px;
      cursor: pointer;
      .line {
        width: 32px;
        height: 2px;
        background-color: #ff5e51;
        margin: -5px auto 0 auto;
      }
    }
    .on {
      color: #333333 !important;
      font-weight: bold !important;
    }
  }
  .contentBox {
    width: 100%;
    padding: 32px;
    /* 答疑 */
    .contentItem {
      display: flex;
      width: 100%;
      margin-bottom: 50px;
      .headerImg {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        margin-right: 16px;
      }
      .mainContent {
        .askBox {
          display: flex;
          /* align-items: center; */
          margin-bottom: 23px;
          .askFrame {
            width: 22px;
            height: 22px;
            background-color: #ff5e51;
            border-radius: 4px;
            font-size: 14px;
            color: #ffffff;
            margin-right: 8px;
            line-height: 22px;
            text-align: center;
          }
          .askContent {
            color: #666666;
            width: 1050px;
            .imgBox {
              display: flex;
              margin-top: 23px;
              .imgItem {
                width: 120px;
                height: 120px;
                border-radius: 8px;
                margin-right: 16px;
              }
            }
          }
        }
        .answer {
          width: 1092px;
          padding: 20px;
          background-color: #f5f7f9;
          color: #666666;
          border-radius: 8px;
          /* display: flex; */
          margin-bottom: 20px;
          .answerItem {
            display: flex;
            margin-bottom: 20px;
          }
          .answerFrame {
            width: 22px;
            height: 22px;
            background-color: #46c287;
            border-radius: 4px;
            margin-right: 8px;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
            text-align: center;
          }
          .answerContent {
            width: 1008px;
            .imgBox {
              display: flex;
              margin-top: 23px;
              .imgItem {
                width: 120px;
                height: 120px;
                border-radius: 8px;
                margin-right: 16px;
              }
            }
          }
        }
        .dateBox {
          color: #999999;
          font-size: 14px;
        }
      }
    }
    /* 评价 */
    .evaluationBox {
      .evaluationBar {
        margin-bottom: 42px;
        .titleBox {
          display: flex;
          margin-bottom: 16px;
          .evaluationTitle {
            color: #333333;
            margin-right: 12px;
          }
        }
        .btnBox {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .submitBtn {
            width: 120px;
            height: 40px;
            border-radius: 4px;
            background-color: #ff5e51;
            color: #ffffff;
            text-align: center;
            line-height: 40px;
            margin-top: 22px;
            cursor: pointer;
          }
        }
      }
      .evaluationItem {
        width: 100%;
        display: flex;
        margin-bottom: 45px;
        .stuImg {
          width: 48px;
          height: 48px;
          border-radius: 24px;
          margin-right: 16px;
        }
        .mainContent {
          width: 1092px;
          .stuName {
            color: #333333;
            margin-bottom: 8px;
          }
          .date {
            font-size: 14px;
            color: #999999;
            margin-bottom: 21px;
          }
          .content {
            color: #666666;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .lectrue {
    .lectrue_list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .lectrue_item {
        width: 280px;
        height: 278px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        margin-right: 26px;
        margin-bottom: 20px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .imgBox {
          width: 100%;
          height: 157px;
          border-radius: 6px 6px 0px 0px;
          overflow: hidden;
          opacity: 1;
          img {
            width: 100%;
            height: 100%;
          }
          .tips {
            position: relative;
            z-index: 999;
            top: -157px;
            right: -214px;
            display: flex;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            text-align: center;
            img {
              width: 66px;
              // height: 22px;
            }
            .tips-type{
              position: absolute;
              top: 130px;
              left: -200px;
              padding: 2px 5px;
              border-radius: 16px;
              background: rgba(0,0,0,0.4);
              font-size: 10px;
              letter-spacing: 1px;
            }
          }
        }
        .content {
          position: relative;
          width: 100%;
          height: 121px;
          background-image: url("~@/assets/img/partner/teatilbac.png");
          background-size: 280px 121px;
          background-repeat: no-repeat;
          padding: 16px;
          .fire {
            margin-top: 20px;
            align-items: center;
            height: 17px;
            font-size: 12px;
            line-height: 17px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #777a82;
            img {
              width: 12px;
              height: 15.5px;
            }
          }
          .title {
            width: 243px;
            height: 46px;
            font-size: 16px;
            line-height: 23px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #3e1623;
          }
          .price {
            position: absolute;
            right: 18px;
            bottom: 18px;
            // width: 57px;
            height: 25px;
            font-size: 18px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #fb2d25;
            line-height: 25px;
          }
        }
      }
    }
  }
  .course_box {
    .course_item {
      display: flex;
      width: 1156px;
      height: 184px;
      margin-bottom: 20px;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(204, 204, 204, 0.3);
      padding: 16px;

      .img_box {
        width: 246px;
        height: 152px;
        border-right: 1px #eeeeee solid;
        position: relative;

        .img {
          margin-top: 16px;
          width: 220px;
          height: 123.75px;
        }
        .kcName {
          width: 180px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-55%, -50%);
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .carouse_box {
        width: 871px;
        margin-left: 16px;

        .tag {
          color: #666666;
          font-size: 14px;
          margin-bottom: 8px;
        }
        .course {
          width: 394px;
          height: 126px;
          border-radius: 8px;
          background-color: #f8f9fa;
          padding: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .img {
            width: 166px;
            height: 94px;
            border-radius: 8px;
            margin-right: 8px;
          }
          .course-title_box {
            .title {
              margin-bottom: 16px;
              width: 202px;
            }
            .price {
              color: #ff5e51;

              .symbol {
                font-size: 14px;
              }
              .num {
                font-size: 18px;
                font-weight: 600;
              }
            }
          }
        }
        .m_l_34 {
          margin-left: 34px;
          margin-right: 15px;
        }
      }
    }
  }
}
.carouse_box ::v-deep .el-carousel__arrow {
  width: 20px !important;
  height: 40px !important;
  border-radius: 4px !important;
}
.carouse_box ::v-deep .el-carousel__arrow--left {
  left: 0;
}
.carouse_box ::v-deep .el-carousel__arrow--right {
  right: 0;
}
</style>
